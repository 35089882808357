<template>
  <div class="block-wrapper" id="关于我们">
    <div class="container ">
      <div class="about_img">
        <img src="/images/banner3.png" />
      </div>

      <div class="about_cont">
        <h3>
          关于我们
        </h3>
        <p class="content">About us</p>
        <div class="content">
          东耀教育投资有限公司是一家致力于智慧教育领域的企业，我们专注于为学校提供全方位的数字化解决方案。通过我们的智慧教育在线数字化平台以及局校一体化系统优势，让学校的管理、教学和服务更加高效便捷。我们拥有强大的技术解决方案，可以为学校量身定制最适合的数字化转型方案。作为智慧教育行业的佼佼者，我们致力于推动中国教育事业的数字化升级，并为广大学生带来更好的学习体验。
        </div>
        <a href="about.html" class="more" data-animate="bounceIn"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  background: #f0f0f0;
  padding: 70px 0px;
  position: relative;
}
.container {
  width: 1310px;
  max-width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;

  .about_img {
    width: 30%;
    img {
      display: inline-block;
      width: 100%;
      padding-bottom: 20px;
      vertical-align: middle;
    }
  }
  .about_cont {
    display: flex;
    flex-direction: column;
    width: 50%;

    h3 {
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 0px;
      color: #444;
    }

    .content {
      padding: 0;
      color: #848484;
      margin-top: 20px;
      background: none;
      font-size: 16px;
      line-height: 1.8;
      word-wrap: break-word;
    }
  }
}
</style>
