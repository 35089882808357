<template>
  <!-- 技术解决方案 -->
  <div class="block-wrapper">
    <div id="解决方案" class="solve">
      <span class="title">SOLUTION BUSINESS</span>
      <p class="desc">技术解决方案</p>
    </div>
    <div class="product_mobile">
      <el-row :gutter="15" type="flex">
        <el-col :span="5">
          <div class="product_right_one">
            <div class="card" v-for="(item, index) in solution" :key="index">
              <div class="title">{{ item.title }}</div>
              <!-- <div class="content">{{ item.content }}</div> -->
            </div>
          </div>
        </el-col>
        
        <el-col :span="3">
            <div class="arrow">
                <div class="title">数据服务</div>
                <img src="/images/Arrow.png" alt="">
            </div>
        </el-col>

        <el-col span="8">
          <div class="product_right_two">
            <div class="card" v-for="(item, index) in system" :key="index">
              <div class="title">{{ item.title }}</div>
              <div class="content">
                <div v-for="(desc, i) in item.content" :key="i">
                  {{ desc }}
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="3">
            <div class="arrow">
                <div class="title">数据应用</div>
                <img src="/images/Arrow.png" alt="">
            </div>
        </el-col>


        <el-col span="5">
          <div class="product_right_three">
              <!-- <div class="title">{{ item.title }}</div> -->
                <div class="item" v-for="(desc, i) in decide_system[0].content" :key="i">
                  {{ desc }}
                </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartTwo",
  data() {
    return {
      solution: [
        {
          title: "认证管理",
          content: "统一认证,单点登录,行政教师,学生,家长一号通用",
        },
        {
          title: "组织管理",
          content: "机构,学校,年级,班级教师,学生基础数据关联",
        },
        {
          title: "应用管理",
          content: "建立应用市场,实现请审,上架,授权,运营管理",
        },
        {
          title: "数据治理",
          content: "数据接入,数据交换,数据目录,数据资产管理",
        },
        {
          title: "数据决策",
          content: "可视化BI工具,数据报表,统计与查询",
        },
      ],
      system: [
        {
          title: "智慧教学应用体系",
          content: [
            "课程资源管理/",
            "自适应学习平台/",
            "项目化/综合活动管理......",
          ],
        },
        {
          title: "精准教育评价体系",
          content: [
            "学校办学发展评价/",
            "教师专业发展评价/",
            "学生综合素质评价/",
            "其它专项评价.....",
          ],
        },
        {
          title: "智慧教育管理体系",
          content: ["平安校园管理/", "智能物联管理/", "局-校办公协作管理"],
        },
        {
          title: "教育民生服务体系",
          content: [
            "阳光招生服务/",
            "体育场馆开放服务/",
            "校车、膳食、宿舍/",
            "课后托管服务",
          ],
        },
      ],
      decide_system: [
        {
          title: "教育发展决策体系",
          content: [
            "区域发展整体情况分析",
            "区域经费执行、资产分析",
            "区域办学发展水平分析",
            "区域学生健康水平分析",
            "区域教师队伍发展水平分析",
            "区域学业质量分析",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$product_right: 490px;
@mixin prodict_box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: $product_right;
}
@mixin title {
  font-size: 1.128rem;
  height: 38px;
  line-height: 38px;
  font-weight: 550;
  text-align: center;
}
.block-wrapper {
  background-color: #f0f0f0;
  padding: 25px 10px;
  overflow: hidden;
  .product_mobile {
    width: 90%;
    align-items: center;
    margin: 20px auto;
  }
  .product_right_one {
    @include prodict_box();
    .card {
      min-height: 80px;
      width: 100%;
      max-width: 260px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 10px 8px;
      margin-top: 10px;
      box-sizing: border-box;
      box-shadow: 0 2px 12px 0 rgba(92, 92, 92, 0.1);
      border-radius: 5px;
      background-color: #5a636d41;
      .content {
        text-align: center;
      }
      .title {
        @include title();
        margin-bottom: 5px;
      }
    }
  }

  .product_right_two {
    @include prodict_box();
    .card {
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      background: radial-gradient(at top left, rgba(156, 154, 154, 0.6) 30%, #c0b4ae 70%);
      .title {
        color: #6c63ff;
        @include title();
      }
      .content {
        display: flex;
        justify-content: center;
        padding: 5px 0;
        border-radius: 10px;
      }
    }
  }

  .product_right_three {
    @include prodict_box();
    align-items: flex-start;
    .title {
      color: #6c63ff;
      @include title();
    }
      .item {
        width: 100%;
        max-width: 200px;
        margin: 5px;
        padding: 20px 20px;
        text-align: center;
        // border: 1px solid #746ee0a8;
      box-shadow: 0 2px 12px 0 rgba(43, 136, 223, 0.5);
        background-color: #3c28f085;
        color: #fff;
        border-radius: 5px;
        font-weight: 550;
      }
  }
}

.arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .title{
        padding: 15px 5px;
        width: 20px;
        border: 5px dotted #e2730a;
        font-weight: 550;
        font-size: 1.2rem;
        z-index: 1;
    }
    img{
        height: auto;
        width: 120px;
    }
}
</style>
