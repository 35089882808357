import Vue from 'vue'
import Router from 'vue-router'
import Index from '../pages/Index'
import Fruit from '../components/Fruit'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/fruit',
            name: 'Fruit',
            component: Fruit
        },
     
    ]
})
