<template>
  <footer class="copyright-section">
    <div class="container text-center">
      <div class="footer-menu">
        <!-- <ul>
          <li><a href="#">Privacy &amp; Cookies</a></li>
          <li><a href="#">Terms &amp; Conditions</a></li>
          <li><a href="#">Accessibility</a></li>
        </ul> -->
      </div>

      <div class="copyright-info">
        <div style="margin-bottom: 10px">
          <a href="https://beian.miit.gov.cn/">粤ICP备19085922号</a>
        </div>
        <span>Copyright © 2019.惠州市东耀教育投资有限公司 </span>
      </div>
    </div>
    <!-- /.container -->
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.copyright-section {
  background-color: #f2f5f8;
  padding: 45px 0;

  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        line-height: 32px;
        position: relative;
        display: inline-block;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    a {
      color: #7b8a97;
      background-color: transparent;
    }
    a,
    a:active,
    a:focus,
    a:active {
      text-decoration: none;
      outline: none;
    }
  }

  .copyright-info {
    margin-top: 14px;
    color: #b4b4b4;
    display: block;
    font-size: 14px;
  }
}
.text-center {
  text-align: center;
}
</style>
