<template>
  <div id="app">
    <div class="header">
      <div class="logo_img" @click="logoClick">
        <div
          style="display:flex; align-items: center; float:left; height: 65px;"
        >
          <div class="header-logo">
            <img id="header-img" src="../../public/logo1.png" />
          </div>
        </div>
        <div style="display:flex; float:left; height: 65px;">
          <div class="header-important">
            <div class="company_name">东耀智慧教育数字化平台</div>
            <div class="header-title company_name">
              Education Digital Platform
            </div>
          </div>
        </div>
      </div>
      <div id="menu_index" style="float: right;">
        <!-- web端菜单导航 -->
        <el-menu
          v-if="seen"
          :default-active="activeIndex"
          class="el-menu-demo"
          menu-trigger="click"
          text-color="#333"
          style="height: 65px; border:0;"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1" class="header-title">首页</el-menu-item>
          <el-menu-item index="2" class="header-title">愿景目标</el-menu-item>
          <el-menu-item index="3" class="header-title">解决方案</el-menu-item>
          <el-menu-item index="4" class="header-title">产品优势</el-menu-item>
          <el-menu-item index="5" class="header-title">关于我们</el-menu-item>
        </el-menu>
        <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
        <el-menu
          v-if="!seen"
          :default-active="activeIndex"
          class="el-menu-demo"
          menu-trigger="click"
          text-color="#333"
          style="height: 65px; border:0;"
          mode="horizontal"
          @select="handleSelectMobile"
        >
          <el-submenu index="1" class="header-title mobile_menu">
            <template slot="title">{{ currentMenu }}</template>
            <el-menu-item index="1" class="header-title">首页</el-menu-item>
            <el-menu-item index="2" class="header-title">愿景目标</el-menu-item>
            <el-menu-item index="3" class="header-title">解决方案</el-menu-item>
            <el-menu-item index="4" class="header-title">产品优势</el-menu-item>
            <el-menu-item index="5" class="header-title">关于我们</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="container" style="clear: both;">
      <router-view></router-view>
    </div>

    <Footer></Footer>

    <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <img src="../assets/other/launch.png" style="height: 45px;width: 35px" />
    </div>
  </div>
</template>

<script>
import Footer from "./Footer.vue";

export default {
  name: "Layout",
  components: { Footer },
  data() {
    return {
      activeIndex: "1",
      company: {
        qrcode: require("../../static/other/公众号二维码.jpg"),
      },
      scroll: "",
      timer: "",
      seen: true,
      currentMenu: "首页",
    };
  },
  methods: {
    getElementY(id) {
      const el = document.getElementById(id);
      document.documentElement.scrollTo({
        top: el.offsetTop,
        behavior: "smooth",
      });
      //   this.timer = setInterval(() => {
      //     let osTop =
      //       document.documentElement.scrollTop || document.body.scrollTop;

      //     let top = el.offsetTop - osTop;

      //     let ispeed = Math.ceil(top / 5);
      //     document.documentElement.scrollTop = document.body.scrollTop =
      //       osTop + ispeed;
      //       console.log(osTop);

      //     if (osTop >= el.offsetTop - 20) {
      //       clearInterval(this.timer);
      //     }
      //   }, 30);
    },

    handleSelect(key) {
      switch (key) {
        case "1":
          this.$router.push({ name: "Index" });
          break;
        case "2":
          this.getElementY("愿景目标");
          break;
        case "3":
          this.getElementY("解决方案");
          break;
        case "4":
          this.$router.push({ name: "Index" });
          this.getElementY("产品优势");
          break;
        case "5":
          this.getElementY("关于我们");
          break;
      }
    },
    handleSelectMobile(key) {
      switch (key) {
        case "1":
          this.$router.push({ name: "Index" });
          this.currentMenu = "首页";
          break;
        case "2-1":
          this.$router.push({ name: "Index" });
          window.location.hash = "#产品微观";
          this.currentMenu = "首页";
          break;
        case "2-2":
          this.$router.push({ name: "Fruit" });
          this.currentMenu = "生鲜果蔬";
          break;
        case "2-3":
          this.$router.push({ name: "Fashion" });
          break;
        case "3":
          this.$router.push({ name: "Index" });
          window.location.hash = "#产品特点";
          this.currentMenu = "首页";
          break;
        case "4":
          this.$router.push({ name: "Index" });
          window.location.hash = "#合作伙伴";
          this.currentMenu = "首页";
          break;
        case "5":
          this.$router.push({ name: "Index" });
          window.location.hash = "#关于我们";
          this.currentMenu = "首页";
          break;
      }
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 600) {
        //判断滚动后高度超过400px,就显示
        this.$refs.btn.style.display = "block";
      } else {
        this.$refs.btn.style.display = "none";
      }
    },
    backTop() {
      //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
      this.timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer);
        }
      }, 30);
    },
    login() {
      window.open("https://admin.fastice-tech.com/login");
    },
    register() {
      window.open("https://admin.fastice-tech.com/registe");
    },
    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    logoClick() {
      this.$router.push({ name: "Index" });
      this.currentMenu = "首页";
    },
  },
  mounted() {
    this.menuSeen();
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.logo_img {
  float: left;
  width: 40%;
  cursor: pointer;
}
#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}
.header-title {
  font-size: 1em;
}
.company_name {
  font-weight: normal;
  font-size: 1em;
}
.footer_font {
  font-size: 1em;
}
#menu_index {
  height: 100%;
}
.header {
  margin: auto;
  width: 80%;
  height: 65px;
  /*background: #F56C6C;*/
}
@media screen and (max-device-width: 415px) {
  .logo_img {
    float: left;
    width: auto;
    cursor: pointer;
  }
  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer;
  }
  .footer_font {
    font-size: 0.875em;
  }
  #menu_index {
    float: right;
    width: auto;
    height: 100%;
  }
}
#header-img {
  width: 50px;
  height: 50px;
  float: left;
  margin: auto;
}
.header-logo {
  height: 50px;
  width: 50px;
  align-self: center;
}
.header-important {
  float: left;
  margin-left: 6px;
  align-self: center;
}

.footer {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
</style>
