<template>
  <div class="block-wrapper">
    <div id="愿景目标" class="solve">
      <span class="title">  OUR PROJECT </span>
      <p class="desc">
        以“人”为主线，让数据赋能每一个业务角落，提高办学水平，提升管理效能、增强社会满意度
      </p>
    </div>
    <!-- 盒子 -->
    <div class="border_parent">
      <el-row :gutter="25" type="flex" justify="center">
        <el-col :sm="6">
          <div class="border">
            <div class="border_title">{{ images[0].title }}</div>
            <div class="border_img_parent">
              <img :src="images[0].img" class="img_border" />
            </div>
            <div class="border_content_one">{{ images[0].content[0] }}</div>
          </div>
        </el-col>

        <el-col :sm="12">
          <el-row>
            <el-col :sm="12">
              <div class="border border-right">
                <div class="border_title">{{ images[1].title }}</div>

                <div class="flex_row">
                  <div class="border_img_parent">
                    <img :src="images[1].img" class="img_border" />
                  </div>
                  <div class="right">
                    <div class="content_one">
                      {{ images[1].content[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :sm="12">
              <div class="border border-right">
                <div class="border_title">{{ images[2].title }}</div>

                <div class="flex_row">
                  <div class="border_img_parent">
                    <img :src="images[2].img" class="img_border" />
                  </div>
                  <div class="right">
                    <div class="content_one">
                      {{ images[2].content[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sm="12">
              <div class="border border-right">
                <div class="border_title">{{ images[3].title }}</div>

                <div class="flex_row">
                  <div class="border_img_parent">
                    <img :src="images[3].img" class="img_border" />
                  </div>
                  <div class="right">
                    <div class="content_one">
                      {{ images[3].content[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :sm="12">
              <div class="border border-right">
                <div class="border_title">{{ images[4].title }}</div>

                <div class="flex_row">
                  <div class="border_img_parent">
                    <img :src="images[4].img" class="img_border" />
                  </div>
                  <div class="right">
                    <div class="content_one">
                      {{ images[4].content[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartOne",
  data() {
    return {
      images: [
        {
          img: require("../../../public/images/desc2.svg"),
          title: "学校",
          content: [
            "向敏捷、协作、主动的服务赋能型组织转型，促进教育自由激发学习活力，满足学习者的需求、兴趣和能力。",
          ],
          content2: ["“用数据记录学校办学发展，打造个性鲜明的学校”"],
        },
        {
          img: require("../../../public/images/desc1.svg"),
          title: "教育局",
          content: ["实现局-校内部数字化协作管理，数据化决策服务"],
          content2: ["高效处置一件事", "“数据洞察一盘棋”"],
        },
        {
          img: require("../../../public/images/desc0.svg"),
          title: "智慧城市",
          content: ["实现教育、民生、公安、体育集约化协作管理"],
          content2: ["“一事一码”", "“一网一窗”", "“一数一源”", "“一屏一库”"],
        },
        {
          img: require("../../../public/images/desc3.svg"),
          title: "教师(班主任)",
          content: [
            "提供教师个体专业能力提升的实践路径;建立教师梯队、人才遵选的评价机制",
          ],
          content2: [
            "“用数据记录教师专业能力发展与提升，提供更清晰的实践路径”",
          ],
        },
        {
          img: require("../../../public/images/desc4.svg"),
          title: "学生",
          content: [
            "提供丰富的学习资源、多元的学习模式，全面提升能力素养、学科素养",
          ],
          content2: ["“用数据记录孩子的成长轨迹，实现五育并举全面发展”"],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-radius: 6px;
  /* border: 1px solid #afc6fd; */
  margin: 10px 0 0 10px;
  height: 425px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .border_title {
    background-color: #8aaaf5;
    font-family: "PingFang SC";
    height: 30px;
    line-height: 30px;
    font-size: 1.125em;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
}

.border_content_one {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.125rem;
  font-weight: 500;
  color: #6b6b6e;
  line-height: 38px;
  overflow: hidden;
}

.border_content_two {
  font-size: 1.18rem;
  font-weight: 550;
  margin-top: 8px;
  text-align: center;
}

.img_border {
  margin-top: 15px;
  width: 80px;
  height: 80px;
}
.flex_row {
  display: flex;
  padding: 10px;
  .border_img_parent {
    margin: 10px 15px;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content_one {
      margin: 20px 0;
      font-size: 1rem;
      line-height: 30px;
      font-weight: 500;
      color: #6b6b6e;
      overflow: hidden;
    }
    .content_two {
      font-size: 1.08rem;
      font-weight: 550;
      margin-top: 8px;
      text-align: center;
    }
  }
}

.border-right {
  height: 207.5px;
}

.border_one {
  margin-left: 0;
}

.border_img_parent {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.desc {
  font-weight: 500;
  font-size: 1rem;
  color: #898b8f;
}
.block-wrapper {
  background-color: #fff;
//   background-color: #f3f3fb;
  padding: 25px 10px;
  overflow: hidden;
}
.border_parent {
  width: 85%;
  margin: 0 auto;
  user-select: none;
}
.border_img_parent {
  margin-top: 0;
}

@media screen and (max-device-width: 415px) {
  .border_parent {
    display: flex;
    width: 90%;
    margin: auto;
    height: auto;
    flex-direction: column;
  }

  .border {
    border-radius: 6px;
    border: 1px solid #ebeef5;
    float: left;
    width: 100%;
    height: auto;
    margin: 10px 0 auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    /*background: aquamarine;*/
  }
}
</style>
