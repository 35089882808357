<template>
  <div class="block-wrapper">
    <div class="flex-row">
      <div class="flex-left">
        <div class="content">
          <div>
            <div id="产品优势" class="solve ">
              <span class="title">SYSTEM ADVANTAGE</span>
              <p class="desc">系统优势</p>
            </div>
          </div>

          <p class="overview">
            数字化已成为现代社会的核心，尤其在数字化转型的进程中。一个数字底座可以为各种数字化场景服务，并为各委办局数据共享提供一个安全可靠的平台。在数字化场景服务体系中，数据可以提供更有针对性的服务，从而为企业和个人获取更好的体验。数字化场景服务体系建立了五类数字化场景，包括数字门户、数字服务、社交服务、生活服务和交通服务。各个委办局可以通过数数字底座和数字化场景服务体系，更好地共享数据和资源，提高效率和服务质量，以促进更好的社会发展。
          </p>
        </div>
      </div>
      <div class="container">
        <div class="desc">
          <el-row type="flex" justify="center">
            <el-col :span="7">
              <div class="left">
                <div class="item" v-for="(item, i) in spec.left" :key="i">
                  {{ item.title }}
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="img-wrapper">
                <img class="center-img" src="/images/part3.png" alt="" />
              </div>
            </el-col>
            <el-col :span="7">
              <div class="right">
                <div class="item" v-for="(item, i) in spec.right" :key="i">
                  {{ item.title }}
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartThree",
  data() {
    return {
      spec: {
        left: [
          {
            title: "大数据中心",
          },
          {
            title: "民政局",
          },
          {
            title: "公安局",
          },
        ],
        right: [
          {
            title: "财政局",
          },
          {
            title: "发改委",
          },
          {
            title: "体育局",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  justify-content: space-around;
  align-items: start;

  .flex-left {
    width: 50%;
    display: flex;
    justify-content: end;
    .content {
      width: 70%;
      padding: 20px;

      .overview{
        text-indent: 50px;
        line-height: 36px;
      }
    }
  }
}
.desc {
  width: 100%;
  .img-wrapper {
    display: flex;
    justify-content: center;

    .center-img {
      width: 330px;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    .item {
      width: 120px;
      border: 2px solid #dda50c;
      border-radius: 5px;
      padding: 5px 10px;
      line-height: 30px;
      text-align: center;

      &:nth-child(odd) {
        margin-right: -65px;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 100%;
    .item {
      width: 120px;
      border: 2px solid #dda50c;
      border-radius: 5px;
      padding: 5px 10px;
      line-height: 30px;
      text-align: center;
      &:nth-child(odd) {
        margin-left: -65px;
      }
    }
  }
}
.block-wrapper {
  //   background-color: #f3f3f3;

  padding: 25px 10px;
  overflow: hidden;
}

.container {
  width: 860px;
  max-width: 60%;
  margin: 20px auto;
  padding: 50px 0;

  .card {
    width: 120px;
    border: 1px solid #e97f38;
    border-radius: 5px;
    padding: 5px 10px;
    line-height: 30px;
    text-align: center;
  }
}

.font_size_30 {
  font-size: 1.25em;
}
.product_mobile_content {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product_mobile_img {
  width: 50%;
}

.product_mobile_title {
  font-weight: bold;
  text-align: center;
}

.product_pc_one {
  text-align: center;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
