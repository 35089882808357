<template>
  <div>
    <el-carousel :height="computeHeight">
      <el-carousel-item v-for="(item, index) in sliders" :key="index">
        <img :src="item.img" class="slider_img" />
      </el-carousel-item>
    </el-carousel>

    <PartOne></PartOne>

    <PartTwo></PartTwo>

    <PartThree></PartThree>

    <!-- 关于我们-->
    <About></About>
    <!-- 合作伙伴-->
    <div id="合作伙伴" class="solve cooperation head-label">
      合作伙伴
    </div>
    <div class="cooperation_parent"></div>
    <!-- 关于我们-->
  </div>
</template>
<script>
import About from "../components/About.vue";
import PartOne from "./components/PartOne.vue";
import PartThree from "./components/PartThree.vue";
import PartTwo from "./components/PartTwo.vue";
export default {
  name: "Index",
  components: { PartOne, PartTwo, PartThree, About },
  data() {
    return {
      sliders: [],
      manages: [
        {
          img: [require("../../static/other/图片1.png")],
          title: "小程序+公众号一体化微信商城管理平台",
          content: [
            "公众号菜单自定义发布 微信图文图片素材管理",
            "商品多规格SKU自定义上架 分类自定义设置",
            "用户管理 用户自定义标签，了解用户消费行为",
            "订单管理 实时掌握商品交易详情",
            "物流闪电发货 实时掌握物流追踪状态",
          ],
        },
      ],
      spec: {
        img: require("../../static/other/arrow.png"),
        product: [
          {
            img: require("../../static/other/手指.png"),
            title: "一键建铺",
            contents: ["注册一键创建店铺", "开启您的专属店铺"],
          },
          {
            img: require("../../static/other/店铺管理.png"),
            title: "店铺装扮",
            contents: ["不同业态打造不同风格", "给用户流畅的视觉体验"],
          },
          {
            img: require("../../static/other/数据分析.png"),
            title: "营销分析",
            contents: ["多玩法提升品牌知名度", "优惠福利刺激付费转化"],
          },
          {
            img: require("../../static/other/助力零售.png"),
            title: "助力零售",
            contents: ["提供专业产品服务", "帮助线下零售业态提升营销"],
          },
        ],
      },
      company: [
        "企业从事于：互联网整合营销，互联网软件开发，微信公众号，小程序，微商城开发，提供技术支持，是所创业型服务研发公司。",
        "核心理念：打造移动电子商务平台 ，提供一键式部署，实现简单可靠稳定的商务平台。",
        "团队作风：我们来自不同地区，因梦想相聚，组建团队，每个团队成员如同人的五官，缺一不可。",
        "企业精神：以实力为盾，自信为矛。我们坚信成功靠朋友，成长靠对手，成就靠团体。",
      ],
      carousel_height: "550px",
    };
  },
  methods: {},
  mounted() {
    this.sliders = [
      {
        img: require("../../public/images/banner1.png"),
      },
      {
        img: require("../../public/images/banner2.jpg"),
      },
    ];
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        return "550px";
      } else {
        return "250px";
      }
    },
    computeArrowImg() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 450) {
        return require("../../static/other/arrow.png");
      } else {
        return require("../../static/other/arrow_down.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider_img {
  height: 550px;
  width: 100%;
}

.font_size_30 {
  font-size: 1.875em;
}

.product_font_style {
  font-size: 1.125em;
}

.product_pc_img {
  width: 100%;
}

.product_pc_img_div {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
}

.product_pc {
  display: flex;
  width: 90%;
  margin: 20px auto;
  background: #f2f6fc;
  align-items: center;
}

.product_spec_parent {
  display: flex;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto;
}

.cooperation_parent {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto;
}

.border_spec {
  border-radius: 6px;
  border: 1px solid #ebeef5;
  float: left;
  width: 25%;
  height: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.product_mobile_img_div {
  width: 50%;
  height: auto;
}

.product_mobile_content_one {
  margin-top: 20px;
  text-align: center;
}

.product_mobile_content_two {
  margin-top: 10px;
  text-align: center;
}

/* 屏幕设备适配 max-device-width: 400px */
@media screen and (max-device-width: 415px) {
  .slider_img {
    height: 250px;
    width: 100%;
  }

  .product_mobile {
    width: 90%;
    align-items: center;
    margin: 20px auto;
    height: auto;
    background: #f2f6fc;
  }

  .product_mobile_content {
    width: 100%;
    margin-top: 15px;
  }

  .product_mobile_content_one {
    margin-top: 5px;
  }

  .product_mobile_content_two {
    margin-top: 5px;
  }

  .product_mobile_img_div {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .product_mobile_img {
    float: left;
  }

  .product_mobile_title {
    font-weight: bold;
  }

  .product_pc_one {
    width: 100%;
    margin-top: 15px;
  }

  .font_size_30 {
    font-size: 1.25em;
  }

  .product_font_style {
    font-size: 1em;
  }

  .product_pc_img {
    height: 280px;
    width: 320px;
  }

  .product_pc_img_div {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .product_pc {
    display: flex;
    width: 90%;
    height: auto;
    margin: 20px auto;
    background: #f2f6fc;
    flex-direction: column;
  }

  .product_spec_parent {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    height: auto;
    flex-direction: column;
  }

  .border_spec {
    float: left;
    height: auto;
    margin: 8px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .border_title {
    font-family: "PingFang SC";
    margin-top: 5px;
    font-size: 1.125em;
    font-weight: bold;
  }

  .border_content_one {
    margin-top: 5px;
    font-size: 1.125em;
  }

  .border_content_two {
    margin-top: 5px;
    font-size: 1.125em;
    margin-bottom: 8px;
  }

  .img_border {
    margin-top: 8px;
    width: 80px;
    height: 80px;
  }

  .border_spec_inner_one {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    /*background-color: aqua;*/
    height: 70px;
  }

  .border_spec_content_one {
    margin-top: 5px;
    font-size: 1.125em;
    float: left;
  }

  .border_spec_inner_last {
    /*background-color: bisque;*/
    margin-top: 5px;
    font-size: 1.125em;
    float: left;
    margin-bottom: 8px;
  }

  .about_me {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }
}

.border_spec_img {
  width: 75px;
  height: 55px;
  align-self: center;
}
</style>
